// Gatsby requirements
import React, { useState, useEffect } from "react"
import { Link, graphql } from 'gatsby'
 
// Components
import Seo from "../components/Layout/Seo"
import Layout from "../components/Layout/Layout"

// Storyblok preview
import { storyblokEditable } from "gatsby-source-storyblok"

// Storyblok Components
import StoryblokComponents from 'utils/StoryblokComponents'

 
const NotFoundPage = ({ data }) => {

  const [previewStory, setPreviewStory] = useState();
  
  useEffect(() => {
    if (window.location.search.includes('_storyblok')) {
        const url = `https://api.storyblok.com/v2/cdn/stories/${window.location.pathname}?version=draft&token=${process.env.GATSBY_STORYBLOK_ACCESS_TOKEN}&cv=${Date.now()}`;
    
        const fetchData = async () => {
            try {
                const response = await fetch(url);
                const json = await response.json();
                setPreviewStory(json.story);
            } catch (error) {
                console.log("error", error);
            }
        };
    
        fetchData();

        setTimeout(() => {
          const sbBridge = new window.StoryblokBridge();
          sbBridge.on(["input", "published", "change"], (event) => {
            setPreviewStory(event.story);
          });
        }, 1000);

    }
  }, []);

  return (
    <Layout footerData={data.footer} headerData={data.header}>

      <Seo
          title="404 – Page Not Found"
          description="Page not found"
      />

      {!previewStory?.content &&
        <main id="content" style={{textAlign: 'center', margin: '7em 1em'}}>
          <h1>Page not found</h1>
          <p style={{maxWidth: '20em', margin: '-1em auto 1em auto'}}>The page you are looking for might have moved elsewhere or the address you entered was incorrect.</p>
          <Link to="/" className="button">Back to homepage</Link>
        </main>
      }

      {previewStory?.content &&
        <main id="content" {...storyblokEditable(previewStory?.content)}>
          <StoryblokComponents content={previewStory?.content?.content}/>
        </main>
      }

    </Layout>
  )
}

export const Head = () => (
  <>
    <title>404 – Page Not Found</title>
    <meta name="robots" content="noindex"/>
  </>
)

export const query = graphql`
  query {
    header: storyblokEntry(full_slug: { eq: "site-configuration/main-header" }) {
        content
    }
    footer: storyblokEntry(full_slug: { eq: "site-configuration/main-footer" }) {
        content
    }
  }
`
 
export default NotFoundPage